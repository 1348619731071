import Navbar from "../../PageElements/Navbar";
import Footer from "../../PageElements/Footer";
import { Typography, Container, Button, Box } from "@mui/material";
import './index.css'

import { useNavigate } from 'react-router-dom';
import { WppButton } from "../../PageElements/WppButton/WppButton";

export default function Error() {
    const navigate = useNavigate();

    const returnHome = () => {
        navigate("/")
    }

    return (
        <>
            <Navbar />
            <WppButton />
            <Container className="menu-error">
                <Box className="box-error">
                    <Typography style={{ marginTop: 20 }}>Essa página não existe!</Typography>
                    <Button onClick={() => returnHome()}>Clique aqui para voltar para pagina inicial</Button>
                </Box>
            </Container>
            <Footer />
        </>
    )
}