import Home from "./Components/Pages/Home";
import Navbar from "./Components/PageElements/Navbar";
import Footer from "./Components/PageElements/Footer";
import { WppButton } from "./Components/PageElements/WppButton/WppButton";

function App() {
  return (
    <>
      <Navbar />
      <WppButton />
      <Home />
      <Footer />
    </>
  );
}

export default App;
