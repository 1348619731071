import { WhatsApp } from "@mui/icons-material";
import "./wppButton.css";
import { Button, Fab } from "@mui/material";

export const WppButton = () => {

    return <div
        style={{
            position: "fixed",
            padding: 60,
            zIndex: 9999,
            float: "right",
            bottom: 0,
            right: 0,
            display: "flex",
        }}
    >
        <Fab color="success">
            <WhatsApp
                className="wpp-style-button"
                color="outline"
                onClick={() => window.open("https://wa.me/5521995925924")}
            />
        </Fab>
    </div>
}